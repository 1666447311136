import React from "react"
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../components/Layout/Layout'

const Template = ({ data, location }) => {
  const page = data.nodePage
    return (
      <Layout location={location} nid={page.drupal_internal__nid}>
        <section className="container mx-auto px-8">
          <div className="page-title">
            {page.title}
          </div>
          {page?.relationships?.field_image &&
            <div className="float-right ml-3 mb-2 w-full md:w-1/3"
            >
              <Img
                fluid={
                  page.relationships.field_image.localFile.childImageSharp.fluid
                } />
            </div>
          }
          <div dangerouslySetInnerHTML={{ __html: page.body.value }}></div>
        </section>
      </Layout>
    )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Template;

export const query = graphql`
  query($pageId: String!) {
    nodePage(id: { eq: $pageId }) {
      id
      title
      drupal_internal__nid
      path {
        alias
      }
      body {
        value
      }
      relationships {
        field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 650 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }`